//Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.

@font-face {
    font-family: 'TWKEverett-Regular-web';
    src: url('../../fonts/TWKEverett-Regular-web.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap; /* Read next point */ 
    unicode-range: U+000-5FF; /* Download only latin glyphs */ 
}

@font-face {
    font-family: 'TWKEverett-Bold-web';
    src: url('../../fonts/TWKEverett-Bold-web.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap; /* Read next point */ 
    unicode-range: U+000-5FF; /* Download only latin glyphs */ 
}

