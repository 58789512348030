// Base
// Includes styles that hardly change and don't require compiling as often

// Tailwind imports
@tailwind base;
@tailwind components;

// Imports
// Include variables, base styles, grids, typography, components and other partials
/* purgecss start ignore */
@import "global/fonts";
/* purgecss end ignore */


// Base styles
html, body {
   //scroll-behavior: smooth;
    -ms-overflow-style: none;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    @apply bg-gray-100;
}

a, a, .button, .link {
    @apply transition duration-300 ease-in-out;
}

:focus-visible {
    outline: none;
}

.video-wrapper {
    padding-bottom: 56.25%;
    @apply relative h-0;
}
.video-wrapper iframe {
    @apply absolute top-0 left-0 w-full h-full;
}